import React from "react"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'

import { MDXRenderer } from "gatsby-plugin-mdx"

import { Layout, SEO } from '../components/common'
// import { MetaData } from '../components/common/meta'


export default function BlogPost({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { mdx } = data // data.mdx holds your post data
  const { frontmatter, body, timeToRead } = mdx

  return (
    <React.Fragment>
      <Helmet />
      <SEO type="article" title={frontmatter.title} description={frontmatter.description} imageUrl={frontmatter.imageUrl}/>

      <Layout>
          <div className="container">
              <article className="content">
                  <section className="post-full-content">
                      <h1 className="content-title" style={{color: '#26a8ed', marginBottom: '0'}}>{frontmatter.title}</h1>
                      <div className="content-subtitle">
                        <div className="date">By {'Fed'} on {frontmatter.date}</div>
                        <div className="time">{timeToRead} min read</div>
                      </div>
                      <section className="content-body">
                        <MDXRenderer>{body}</MDXRenderer>
                      </section>
                  </section>
              </article>
          </div>
      </Layout>
  </React.Fragment>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        imageUrl
        description
      }
    }
  }
`